<template>
  <div class="height-100 max_box">
    <div class="tips">
      风险提示：本视频相关内容不对各位读者构成任何 投资建议，据此操作，风险自担
    </div>
    <div class="audio-box">
      <audio src="audio/AVeryHappyChristmas.mp3" ref="bgAudio"></audio>
      <audio :src="audioSrcList[0]" ref="audio1"></audio>
      <audio :src="audioSrcList[1]" ref="audio2"></audio>
      <audio :src="audioSrcList[2]" ref="audio3"></audio>
      <audio :src="audioSrcList[3]" ref="audio4"></audio>
      <audio :src="audioSrcList[4]" ref="audio5"></audio>
      <audio :src="audioSrcList[5]" ref="audio6"></audio>
      <audio :src="audioSrcList[6]" ref="audio7"></audio>
    </div>
    <div class="card animate__animated animate__fadeIn duration " id="card1">
      <div class="card1_top_box">
        <p class="font-blod">2021年半年度报告</p>
        <p>{{ extra_param_1[0] }}</p>
        <p>{{ extra_param_1[1] }}</p>
      </div>
      <div class="logo-box logo-box3">
        <img src="img/logo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card2">
      <div class="card_main">
        <div class="title">
          <p class="title_l">营业总收入</p>
          <p class="title_r_1">
            <span
              ><i>{{ extra_param_2[0] }}</i
              >亿</span
            ><span>{{ extra_param_2[1] }}%</span>
          </p>
        </div>
        <div id="chart1"></div>
        <div class="title">
          <p class="title_l">归母净利润</p>
          <p class="title_r_1">
            <span
              ><i>{{ extra_param_3[0] }}</i
              >亿</span
            ><span>{{ extra_param_3[1] }}%</span>
          </p>
        </div>
        <div id="chart1_2"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card3">
      <div class="card_main">
        <div class="title">
          <p class="title_l">财务比率</p>
          <p class="title_r_2">2021年中报</p>
        </div>
        <div id="chart2"></div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none"  id="card4">
      <div class="card_main card4_main1 duration animate__fadeIn">
        <div class="text-center font-18 card4_title">
          <span>每股收益 和 每股经营现金流</span>
        </div>
        <div id="chart3_1"></div>
        <div class="card4_des margin-t-8">每股收益<span>{{extra_param_4[0]}}元</span>,同比上涨<span>{{extra_param_4[1]}}</span>%。每股经营性现金流<span>{{extra_param_5[0]}}</span>元,同比上涨<span>{{extra_param_5[1]}}</span>%</div>
      </div>
      <div class="card_main none card4_main2 animate__fadeIn duration">
        <div class="text-center font-18 card4_title">
          <span>每股净资产 和 每股营业收入</span>
        </div>
        <div id="chart3_2"></div>
        <div class="card4_des margin-t-8">每股净资产<span>{{extra_param_6[0]}}</span>元,同比上涨<span>{{extra_param_6[1]}}</span>%。每股营业收入<span>{{extra_param_7[0]}}</span>元,同比上涨<span>{{extra_param_7[1]}}</span>%</div>
      </div>
      <div class="card_main none card4_main3 animate__fadeIn duration">
        <div class="text-center font-18 card4_title">
          <span>净资产收益率 和 销售 毛利率</span>
        </div>
        <div id="chart3_3"></div>
        <div class="card4_des margin-t-8">净资产收益率<span>{{extra_param_8[0]}}</span>%,同比上涨<span>{{extra_param_8[1]}}</span>%。销售毛利率<span>{{extra_param_9[0]}}</span>%,同比上涨<span>{{extra_param_9[1]}}</span>%</div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card5">
      <div class="card_main">
        <div class="title">
          <p class="title_l">十大流通股东</p>
        </div>
        <div class="content_box relative">
          <div id="chart4"></div>
          <div class="card5_aa">
            <p v-for="(item,index) in chart4.aa" :key="index">{{item}}亿</p>
          </div>
          <div class="card5_qq">
            <p v-for="(item,index) in chart4.qq" :key="index">
              <span  v-if="item>0"><img src="../assets/img/up2.png" alt=""></span>
              <span  v-if="item<0"><img src="../assets/img/down2.png" alt=""></span>
              <span v-if="item != '0' " :class="item>0 ? 'isred' : 'islu'">{{Math.abs(item)}}%</span>
            </p>
          </div>
          <div class="card5_qq">
            <p v-for="(item,index) in chart4.chang" :key="index">
              <span></span>
              <span v-if="item == '新进' ">{{item}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card6">
      <div class="card_main">
        <div class="title">
          <p class="title_l">股东人数</p>
          <p class="title_r_2">单位：万户</p>
        </div>
        <div class="content_box">
          <div id="chart5"></div>
        </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7">
      <div class="card_main">
        <div class="title">
          <p class="title_l">行业盈利与估值对比</p>
        </div>
        <div class="content_box relative">
          <div class="card7_des">
            根据申万二级行业分类,<span>{{extra_param_10[0]}}</span>,已披露财报<span>{{extra_param_11[0]}}</span>家公司中，本公司市值规模排名第<span>{{extra_param_12[0]}}</span>,PB(市净率)排名第<span>{{extra_param_12[1]}}</span>,ROE(净资产收益率)排名第<span>{{extra_param_12[2]}}</span>
          </div>
          <div id="chart6"></div>
          <div class="flex flex-between">
            <span>PB(市净率）</span>
            <span>注:圆的大小为市值</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card8">
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/huaanend.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams } from "../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template48",
  data() {
    return {
      model_id: 48,
      audioSrcList:[],
      audioTimeList:[],
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      extra_param_1: [],
      extra_param_2: [],
      extra_param_3: [],
      extra_param_4: [],
      extra_param_5: [],
      extra_param_6: [],
      extra_param_7: [],
      extra_param_8: [],
      extra_param_9: [],
      extra_param_10: [],
      extra_param_11: [],
      extra_param_12: [],
      chart1: {
        x: [],
        y1: [],
        y2: [],
      },
      chart1_2: {
        x: [],
        y1: [],
        y2: [],
      },
      chart2: {
        indicator: [],
        value: [],
      },
      chart3: {x:[],y1:[],y2:[],y3:[],y4:[],y5:[],y6:[]},
      chart4:{aa:[],chang:[],qq:[],rr1:[],shlist:[]},
      chart5: {x:[],y:[]},
      chart6:{},
      recordtimeAll:null,
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { extra_param_1, audioSrcList } = this;
      return {
        extra_param_1,
        audioSrcList,
      };
    },
  },
  watch: {
    allReady(val) {
      if (val.extra_param_1.length > 0 && val.audioSrcList.length>0) {
        if (this.device_id) {
          setTimeout(() => {
            ApiServe.recordStart({
              model_id: this.model_id,
              device_id: this.device_id,
              record_time: this.recordtimeAll,
              real: 1,
            }).then((res) => {
              this.load();
            });
          }, 20000);
        } else {
          setTimeout(() => {
            this.load();
            //this.loadChart1();
            //this.loadChart1_2()
            //this.loadChart2();
            //this.loadChart3_1()
            // this.loadChart4()
            // this.loadChart5()
            // this.loadChart6()
          }, 3000);
        }
      }
    },
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: this.model_id })
        .then(({ data }) => {
          this.extra_param_1 = data.extra_param_1;
          this.extra_param_2 = data.extra_param_2;
          this.extra_param_3 = data.extra_param_3;
          this.extra_param_4 = data.extra_param_4;
          this.extra_param_5 = data.extra_param_5;
          this.extra_param_6 = data.extra_param_6;
          this.extra_param_7 = data.extra_param_7;
          this.extra_param_8 = data.extra_param_8;
          this.extra_param_9 = data.extra_param_9;
          this.extra_param_10 = data.extra_param_10;
          this.extra_param_11 = data.extra_param_11;
          this.extra_param_12 = data.extra_param_12;
          this.chart1 = {
            x: data.chart_data_1[0].data.x,
            y1: data.chart_data_1[0].data.y1,
            y2: data.chart_data_1[0].data.y2,
          };
          this.chart1_2 = {
            x: data.chart_data_1[1].data.x,
            y1: data.chart_data_1[1].data.y1,
            y2: data.chart_data_1[1].data.y2,
          },
          this.chart2.value = [
            data.chart_data_2[0].data["加权净资产收益率"][0],
            data.chart_data_2[0].data["总资产周转率"][0],
            data.chart_data_2[0].data["净利润同比增长率"][0],
            data.chart_data_2[0].data["销售现金比率"][0],
            data.chart_data_2[0].data["资产负债率"][0],
          ];
          // let max = Math.max.apply(Math,this.chart2.value)
          this.chart2.indicator = [
            {
              name1: '盈利能力',name2:'加权净资产收益率',value:this.chart2.value[0],max:this.chart2.value[0]*1.5,
            },
            {
              name1: '运营能力',name2:'总资产周转率',value:this.chart2.value[1],max:this.chart2.value[1]*1.7
            },
            {
              name1: '成长能力',name2:'净利润同比增长率',value:this.chart2.value[2],max:this.chart2.value[2]*1.8
            },
            {
              name1: '现金获取能力',name2:'销售现金比率',value:this.chart2.value[3],max:this.chart2.value[3]*1.3
            },
            {
              name1: '偿债能力',name2:'资产负债率',value:this.chart2.value[4],max:this.chart2.value[4]*1.5
            }
          ]
          this.chart3 = {
            x:[data.chart_data_3[0].data.x[0],data.chart_data_3[1].data.x[0]],
            y1:[data.chart_data_3[0].data.y1[0],data.chart_data_3[1].data.y1[0]],
            y2:[data.chart_data_3[0].data.y2[0],data.chart_data_3[1].data.y2[0]],
            y3:[data.chart_data_3[2].data.y1[0],data.chart_data_3[3].data.y1[0]],
            y4:[data.chart_data_3[2].data.y2[0],data.chart_data_3[3].data.y2[0]],
            y5:[data.chart_data_3[4].data.y1[0],data.chart_data_3[5].data.y1[0]],
            y6:[data.chart_data_3[4].data.y2[0],data.chart_data_3[5].data.y2[0]],
          }
          this.chart4 = data.chart_data_4[0].data
          this.chart5 = data.chart_data_5[0].data
          let chart6Index = data.chart_data_6[0].data.secucode.indexOf(data.extra_param_1[1])
          let arr1 = [data.chart_data_6[0].data.x[chart6Index],data.chart_data_6[0].data.y1[chart6Index]]
          let arr2 = data.chart_data_6[0].data.x.filter((val,index)=>{
            return  index != chart6Index
          })
          let arry1 = data.chart_data_6[0].data.y1.filter((val,index)=>{
            return  index != chart6Index
          })
          let arr3 = data.chart_data_6[0].data.y2.filter((val,index)=>{
            return  index != chart6Index
          })
          let newArr = arr2.map((item,index)=>{
            return [item,arry1[index]]
          })
          this.chart6 ={
              otherCompany:newArr,
              company:[arr1],
              chart6Index,
              companyLarge:data.chart_data_6[0].data.y2[chart6Index],
              otherCompanyLarge:arr3
            }
        })
        .catch((err) => {
          if (this.device_id) {
            ApiServe.releasedSevice({ device_id: this.device_id });
          }
        });
    },
    // 获取旁白
    getAudio() {
      ApiServe.getVideoData({ model_id: this.model_id })
        .then(({ data, code }) => {
          if (code == 200) {
            this.audioSrcList = data.map((item)=>{
              return item.audio_link
            })
            this.audioTimeList = data.map((item)=>{
              return item.scene_duration
            })
            this.recordtimeAll = this.audioTimeList.reduce(function(prev, curr, idx, arr){
              return prev + curr;
            }) + this.audioTimeList.length + 3;  
          }
        })
        .catch((err) => {
          if (this.device_id) {
            ApiServe.releasedSevice({ device_id: this.device_id });
          }
        });
    },
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },
    // 营业总收入柱状图
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          data: this.chart1.x,
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLabel: {
            color: "#9A9A9A",
          },
        },
        legend: {
          data: ["营业收入(元)", "同比"],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 40,
          left: 40,
          right: 20,
          bottom: 40,
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false,
          },
          {
            type: "value",
            name: "",
            // min: 0,
            max: 100,
            position: "left",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false, //y轴线消失
            },
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLabel: {
              shou: true,
              color: "#9A9A9A",
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        ],
        series: [
          {
            name: "营业收入(元)",
            type: "bar",
            color: "#F68630",
            barWidth: 28,
            data: this.chart1.y1,
            label: {
              show: true,
              position: "top",
              color: "#F68630",
              formatter: function (val) {
                return val.value + "亿";
              },
            },
          },
          {
            name: "同比",
            type: "line",
            color: "#666",
            lineStyle: {
              type: "dashed",
            },
            yAxisIndex: 1,
            data: this.chart1.y2,
          },
        ],
      });
    },
    // 归母净利润
    loadChart1_2() {
      let myChart = this.$echarts.init(document.getElementById("chart1_2"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          data: this.chart1_2.x,
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLabel: {
            color: "#9A9A9A",
          },
        },
        legend: {
          data: ["归母净利润(元)", "同比"],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 40,
          bottom: 40,
          left: 40,
          right: 20,
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false,
          },
          {
            type: "value",
            name: "",
            // min: 0,
            max: 100,
            position: "left",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false, //y轴线消失
            },
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLabel: {
              shou: true,
              color: "#9A9A9A",
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        ],
        series: [
          {
            name: "归母净利润(元)",
            type: "bar",
            color: "#F68630",
            barWidth: 28,
            data: this.chart1_2.y1,
            label: {
              show: true,
              position: "top",
              color: "#F68630",
              formatter: function (val) {
                return val.value + "亿";
              },
            },
          },
          {
            name: "同比",
            type: "line",
            color: "#666",
            lineStyle: {
              type: "dashed",
            },
            yAxisIndex: 1,
            data: this.chart1_2.y2,
          },
        ],
      });
    },
    // 营业总收入雷达图
    loadChart2() {
      let myChart = this.$echarts.init(document.getElementById("chart2"));
      // 绘制图表
      myChart.setOption({
        radar: {
          indicator: this.chart2.indicator,
          radius: "50%",
          center:['50%','50%'],
          name: {
            color: "#515151",
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color:'#ccc'
            },
          },
          splitArea: {
            areaStyle: {
              color: "transparent",
            },
          },
          name:{
            formatter: function (value, indicator) {
                return `{a|${indicator.name1}}\n{b|${indicator.name2}\n${indicator.value}%}`
            },

            rich: {
                a: {
                    color: '#000',
                    fontSize:16,
                    padding:[0,0,8,0]
                },
                b: {
                    color: '#515151',
                    fontSize:12,
                }
            }
          }
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: this.chart2.value,
              },
            ],
            itemStyle: {
              color: "rgba(30, 54, 255, 0.6)",
            },
            areaStyle: {
              color: "rgba(30, 54, 255, 0.6)",
            },
          },
        ],
      });
    }, 
    // 每股收益 和 每股经营现金流
    loadChart3_1() {
      var myChart = this.$echarts.init(document.getElementById("chart3_1"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          data: this.chart3.x,
          axisTick: {
            show: false //y轴坐标点消失
          },
          axisLabel: {
            color: "#9A9A9A"
          }
        },
        animationDuration: 2000,
        legend: {
          data: ["每股收益(元)", "每股经营性现金流(元)"],
          left: "center"
        },
        grid: {
          left: 40,
          right: 0,
          bottom:30
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false
          }
        ],
        series: [
          {
            name: "每股收益(元)",
            type: "bar",
            color: "#F8862E",
            barWidth: 28,
            data: this.chart3.y1,
            label: {
              show: true,
              position: "top",
              color: "#F8862E"
            }
          },
          {
            name: "每股经营性现金流(元)",
            type: "bar",
            barWidth: 28,
            color: "#5563D9",
            label: {
              show: true,
              position: "top",
              color: "#5563D9"
            },
            data: this.chart3.y2
          }
        ]
      });
    },
   // 每股净资产 和 每股营业收入
    loadChart3_2() {
      var myChart = this.$echarts.init(document.getElementById("chart3_2"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          data: this.chart3.x,
          axisTick: {
            show: false //y轴坐标点消失
          },
          axisLabel: {
            color: "#9A9A9A"
          }
        },
        legend: {
          data: ["每股净资产(元)", "每股营业收入(元)"],
          left: "center"
        },
        animationDuration: 2000,
        grid: {
          left: 40,
          right: 0,
          bottom:30
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false
          }
        ],
        series: [
          {
            name: "每股净资产(元)",
            type: "bar",
            color: "#F8862E",
            barWidth: 28,
            data: this.chart3.y3,
            label: {
              show: true,
              position: "top",
              color: "#F8862E"
            }
          },
          {
            name: "每股营业收入(元)",
            type: "bar",
            barWidth: 28,
            color: "#5563D9",
            label: {
              show: true,
              position: "top",
              color: "#5563D9"
            },
            data: this.chart3.y4
          }
        ]
      });
    },
   // 净资产收益率 和 销售毛利率
    loadChart3_3() {
      var myChart = this.$echarts.init(document.getElementById("chart3_3"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          data: this.chart3.x,
          axisTick: {
            show: false //y轴坐标点消失
          },
          axisLabel: {
            color: "#9A9A9A"
          }
        },
        legend: {
          data: ["净资产收益率", "销售毛利率"],
          left: "center"
        },
        animationDuration: 2000,
        grid: {
          left: 40,
          right: 0,
          bottom:30
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false
          }
        ],
        series: [
          {
            name: "净资产收益率",
            type: "bar",
            color: "#F8862E",
            barWidth: 28,
            data: this.chart3.y5,
            label: {
              show: true,
              position: "top",
              color: "#F8862E"
            }
          },
          {
            name: "销售毛利率",
            type: "bar",
            barWidth: 28,
            color: "#5563D9",
            label: {
              show: true,
              position: "top",
              color: "#5563D9"
            },
            data: this.chart3.y6
          }
        ]
      });
    },
    // 十大流通股东
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      myChart.setOption({
        grid: {
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max:100,
            show: false,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.chart4.shlist,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 10, 10],
              textStyle: {
                fontSize: 14,
                color: "#6D6D6D",
              },
              formatter(value, index) {
                return value;
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.chart4.rr1,
            barWidth: 16,
            barMinHeight:20,
            barGap: "40px",
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "#FFF",
            },
            itemStyle: {
              normal: {
                color: "#F68831",
                label: {
                  show: true, //开启显示
                  position: 'right', //在上方显示
                  textStyle: { //数值样式
                    color: '#F68831',
                    fontSize: 12
                  },
                  formatter:function(val){
                    return val.value+'%'
                  }
                },
              },
            },
          },
        ],
      });
    },
    // 股东户数
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          data: this.chart5.x,
          axisTick: {
            show: false //y轴坐标点消失
          },
          axisLabel: {
            color: "#9A9A9A"
          }
        },
        grid: {
          left: 0,
          right: 0,
          top:20,
          bottom:20,
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false
          }
        ],
        series: [
          {
            type: "bar",
            color: "#7D9CF3",
            barWidth: 28,
            data: this.chart5.y,
            label: {
              show: true,
              position: "top",
              color: "#7D9CF3"
            }
          }          
        ]
      });
    },    
    // 行业盈利与估值对比散点图
    loadChart6() {
      var myChart = this.$echarts.init(document.getElementById("chart6"));
      // 绘制图表
      myChart.setOption({
        legend: {
          right: "10%",
          top: "3%",
          data: ["本公司", "其他"]
        },
        grid: {
          left: "8%",
          top: "10%",
          bottom:30,
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          }
        },
        yAxis: {
          name:'ROE(净资产收益率）',
          nameTextStyle:{
            padding:[0,0,0,60]
          },
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          },
          axisTick:{
            show:false
          },
          axisLine:{
            show:false
          },
          scale: true
        },
        series: [
          {
            name: "本公司",
            data: this.chart6.company,
            type: "scatter",
            symbolSize: ()=> {
              return Math.sqrt(this.chart6.companyLarge) / 20000;
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(120, 36, 50, 0.5)",
              shadowOffsetY: 5,
              color: new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                {
                  offset: 0,
                  color: "rgba(89, 131, 245, 1)"
                },
                {
                  offset: 1,
                  color: "rgba(43, 126, 235, 1)"
                }
              ])
            }
          },
          {
            name: "其他",
            data: this.chart6.otherCompany,
            type: "scatter",
            symbolSize: ()=> {
              return Math.sqrt(this.chart6.otherCompanyLarge) / 20000;
            },
            itemStyle: {
              color: new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                {
                  offset: 0,
                  color: "rgba(242, 149, 81, 1)"
                },
                {
                  offset: 1,
                  color: "rgba(255, 102, 0, 1)"
                }
              ])
            }
          }
        ]
      });
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.audio1.play();
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, this.audioTimeList[0]*1000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.audio2.play();
        this.loadChart1();
        this.loadChart1_2()
      }, (1+this.audioTimeList[0])*1000); //间隔
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (1+this.audioTimeList[0]+this.audioTimeList[1])*1000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio3.play();
        this.loadChart2();
      }, (2+this.audioTimeList[0]+this.audioTimeList[1])*1000); //间隔
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (2+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2])*1000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.audio4.play();
        this.loadChart3_1()
      }, (3+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2])*1000); //间隔
      setTimeout(() => {
        $('.card4_main1').addClass('animate__fadeOut')
      }, (3+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+3)*1000); //间隔
      setTimeout(() => {
        $('.card4_main1').addClass('none')
        $('.card4_main2').removeClass('none')
        this.loadChart3_2()
      }, (3+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+4)*1000); //间隔
      setTimeout(() => {
        $('.card4_main2').addClass('animate__fadeOut')
      }, (3+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+7)*1000); //间隔
      setTimeout(() => {
        $('.card4_main2').addClass('none')
        $('.card4_main3').removeClass('none')
        this.loadChart3_3()
      }, (3+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+8)*1000); //间隔
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (3+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3])*1000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.$refs.audio5.play();
        this.loadChart4();
      }, (4+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3])*1000); //间隔
      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (4+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3]+this.audioTimeList[4])*1000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.$refs.audio6.play();
        this.loadChart5();
      }, (5+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3]+this.audioTimeList[4])*1000); //间隔
      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (5+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3]+this.audioTimeList[4]+this.audioTimeList[5])*1000);
      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        this.$refs.audio7.play();
        this.loadChart6();
      }, (6+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3]+this.audioTimeList[4]+this.audioTimeList[5])*1000); //间隔
      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, (6+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3]+this.audioTimeList[4]+this.audioTimeList[5]+this.audioTimeList[6])*1000);
      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.$refs.endVideo.play();
      }, (7+this.audioTimeList[0]+this.audioTimeList[1]+this.audioTimeList[2]+this.audioTimeList[3]+this.audioTimeList[4]+this.audioTimeList[5]+this.audioTimeList[6])*1000); //间隔
      // 总时间 94 + 3秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: this.model_id,
            device_id: this.device_id,
            real: 1,
          });
        }, (this.recordtimeAll+8)*1000);
      }
    },
  },
};
</script>
<style scoped lang="less">
.max_box {
  background: url("../assets/img/bg.png") no-repeat center center;
  background-size: cover;
  font-size: 12px;
}
.logo-box{
  width: 200px;
}
.card {
  background: none;
  color: #7e8090;
  font-size: 12px;
  overflow: hidden;
}
.color_EF622E {
  color: #ef622e;
}
.logo-box3 {
  bottom: 160px;
}
.tips {
  position: absolute;
  bottom: 111px;
  left: 50px;
  right: 50px;
  color: #a5a5a5;
  text-align: center;
}
.ab_center {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
.card1_top_box {
  position: absolute;
  top: 206px;
  text-align: center;
  width: 100%;
  > p:first-of-type {
    font-size: 24px;
    font-weight: bold;
    color: #3c3c3c;
    opacity: 1;
  }
  > p:nth-of-type(2) {
    margin: 21px 0 4px;
    font-size: 18px;
    color: #ef622e;
    font-weight: 500;
  }
  > p:nth-of-type(3) {
    color: #ef622e;
    font-weight: 500;
  }
}
#chart1,
#chart1_2 {
  width: 100%;
  height: 204px;
  margin: 20px 0;
}
#chart2{
  margin-top: 80px;
}
#chart2 {
  width: 100%;
  height: 400px;
}
#chart4 {
  width: 100%;
  height: 500px;
}
#chart5{
  width: 100%;
  height: 300px;
  margin-top: 100px;
}
#chart6{
  height: 360px;
  width: 100%;
}
.card_main {
  position: absolute;
  top: 113px;
  left: 16px;
  right: 16px;
  bottom: 0;
}
#card4 .card_main{
  top: 157px;
}
#card5 .card_main{
  top: 103px;
  .content_box{
    margin-top: 10px;
  }
}
#card4 .card_main,#card7 .card_main{
  top: 133px;
}
#chart3_1,#chart3_2,#chart3_3{
  margin-top: 10px;
  height: 300px;
  width: 100%;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 30px;
  .title_l {
    height: 30px;
    font-size: 21px;
    color: #4d4d4d;
    font-weight: bold;
  }
  .title_r_1 {
    font-size: 14px;
    color: #f98329;
    > span:nth-of-type(2) {
      margin: 0 16px;
    }
  }
}
.card4_title{
  color: #000;
}
.card4_des{
  padding: 12px 14px;
  background: #DEE4F7;
  color: #5766DA;
  font-size: 14px;
  line-height: 24px;
}
.card5_aa,.card5_qq{
  position: absolute;
  width: 60px;
  text-align: right;
  right: 4px;
  top: 0px;
  bottom: 0;
  p{
    height: 10%;
  }
}
.card5_aa{
  p{
    color: #676767;
  }
}
.card5_qq p span{
  margin-top: 16px;
  display: inline-block;
}
.card5_qq > p > span:first-of-type{
  width: 14px;
  height: 14px;
  vertical-align: top;
  overflow: hidden;
  margin-right: 4px;
  img{
    width: 100%;
    height: auto;
  }
}
.card7_des{
  line-height: 24px;
  font-size: 14px;
  padding: 12px 0;
  letter-spacing: 1px;
  span{
    color: #FF6600;
  }
}
.islu {
  color: #00b466;
}
.isred {
  color: #e54444;
}
</style>